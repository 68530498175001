<template>
  <div class="container">
    <b-spinner v-if="loading"></b-spinner>
    <template v-if="!loading && customerGroup">
      <div class="cui__utils__heading">
        <strong>Editing {{ customerGroup.description }}</strong>
      </div>
      <b-card no-body>
        <b-tabs
          card
          nav-wrapper-class="bg-light">
          <b-tab title="CLIENT CONFIG" active>
            <EditClientConfigForm
              :clientConfig="clientConfig"
              :errors="errors"
              @submit="onClientConfigSubmit"
            />
          </b-tab>
          <b-tab title="SENDER PROFILES">
            <EditSenderProfilesForm
              :senderProfiles="senderProfiles"
              :save="saveSenderProfiles"
            />
          </b-tab>
          <b-tab title="CLIENT MODEL LINE" no-body>
            <EditClientModelLineForm
              :clientModelLine="clientModelLine"
              :save="saveClientModelLine"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EditClientConfigForm from '@/components/clients/editClientConfigForm.vue';
import EditSenderProfilesForm from '@/components/clients/editSenderProfilesForm.vue';
import EditClientModelLineForm from '@/components/clients/editClientModelLineForm';

export default {
  components: {
    EditClientConfigForm,
    EditSenderProfilesForm,
    EditClientModelLineForm
  },
  data() {
    return {
      user: null,
      errors: [],
      clientID: '',
      customerGroup: null,
      loading: false,
      saving: false,
    }
  },
  computed: {
    ...mapGetters('ocapi', [
      'getClientConfig',
      'getSenderProfiles',
      'getClientModelLine',
    ]),
    clientConfig() {
      return this.getClientConfig(this.customerGroup);
    },
    senderProfiles() {
      return this.getSenderProfiles(this.customerGroup);
    },
    clientModelLine() {
      return this.getClientModelLine(this.customerGroup);
    }
  },
  methods: {
    ...mapActions('ocapi', [
      'fetchCustomerGroup',
      'updateCustomerGroup'
    ]),
    ...mapMutations('admin', [
      'startLoading',
      'stopLoading'
    ]),
    onClientConfigSubmit(data) {
      this.startLoading();
      const request = {
        customerGroupID: this.clientID,
        data: {
          c_clientConfiguration: JSON.stringify(data, null, 2)
        }
      };
      console.log(request);
      this.updateCustomerGroup(request)
        .then(() => {
          this.stopLoading();
        })
        .catch(error => {
          console.error(error);
          this.stopLoading();
        })
    },
    saveSenderProfiles(senderProfiles) {
      const request = {
        customerGroupID: this.clientID,
        data: {
          c_senderProfiles: JSON.stringify(senderProfiles, null, 2)
        }
      };
      return new Promise((resolve, reject) => {
        this.updateCustomerGroup(request)
          .then(response => {
            this.customerGroup = response.data;
            resolve(response);
          })
          .catch(reject);
      })
    },
    saveClientModelLine(clientModelLine) {
      const request = {
        customerGroupID: this.clientID,
        data: {
          c_clientModelLine: JSON.stringify(clientModelLine, null, 2)
        }
      };
      return new Promise((resolve, reject) => {
        this.updateCustomerGroup(request)
          .then(response => {
            this.customerGroup = response.data;
            resolve(response);
          })
          .catch(reject);
      })
    },
  },
  mounted() {
    this.clientID = this.$route.params.id;
    this.loading = true;
    this.fetchCustomerGroup(this.clientID)
      .then(response => {
        this.customerGroup = response.data;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      })
  }
}
</script>
<style lang="scss">
  // .edit-client {
  //   > .tabs > .card-header {
  //     padding-bottom: .75rem;
  //     .nav-link {
  //       @media (min-width: 768px) {
  //         padding: 1rem 2rem;
  //       }
  //     }
  //   }
  // }
  // .default .edit-client > .tabs > .card-header .nav-link.active {
  //   background-color: white;
  // }
  // .dark .edit-client > .tabs > .card-header .nav-link.active {
  //   background-color: #0c0c1b;
  // }
</style>
