<template>
  <div>
    <b-spinner v-if="loading"></b-spinner>
    <template v-if="!loading">
      <SplitListSelect
        v-if="sentimentOptions.length"
        label="Sentiments"
        :options="sentimentOptions"
        v-model="selectedSentiments"
        valueKey="id"
        filterLabel="Filter Sentiments"
        :searchFilter="filterSentiments"
        notSelectedLabel="Enabled Sentiments"
        selectedLabel="Disabled Sentiments"
        nothingSelectedMessage="All Sentiments for this product will be available."
      >
        <template v-slot:default="slotProps">
          <b-avatar
            rounded=""
            :src="slotProps.option.c_hbc_SentimentImage.abs_url"
            variant="white"></b-avatar>
          <small class="d-block">{{ slotProps.option.id }}</small>
        </template>
      </SplitListSelect>
      <p v-else>This product has no sentiments.</p>

      <SplitListSelect
        v-if="messageStarterOptions.length"
        label="Message Starters"
        :options="messageStarterOptions"
        v-model="selectedMessageStarters"
        valueKey="id"
        filterLabel="Filter Message Starters"
        :searchFilter="filterMessageStarters"
        notSelectedLabel="Enabled MessageStarters"
        selectedLabel="Disabled Message Starters"
        nothingSelectedMessage="All Message Starters for this product will be available."
      >
        <template v-slot:default="slotProps">
          {{ slotProps.option.description.default }}
          <small class="d-block">{{ slotProps.option.id }}</small>
        </template>
      </SplitListSelect>
      <p v-else>This product has no message starters.</p>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SplitListSelect from '@/components/splitListSelect.vue';

export default {
  name: 'ConfigureClientModelLineProduct',
  props: [
    'product',
    'value'
  ],
  components: {
    SplitListSelect
  },
  data() {
    return {
      loading: true,
      libraryItems: [],
      selectedSentiments: [],
      selectedMessageStarters: []
    }
  },
  computed: {
    sentimentOptions() {
      return this.libraryItems.filter(item => item.c_hbc_MessageType === 'sentiment');
    },
    messageStarterOptions() {
      return this.libraryItems.filter(item => item.c_hbc_MessageType === 'messageStarter');
    },
  },
  methods: {
    ...mapActions('ocapi', [
      'fetchLibraryFolder'
    ]),
    filterSentiments(options, query) {
      if (query === '') {
        return options;
      }
      return options.filter(option => {
        const regex = new RegExp(`(${query})`, 'i');
        return option?.id?.match(regex);
      })
    },
    filterMessageStarters(options, query) {
      if (query === '') {
        return options;
      }
      return options.filter(option => {
        const regex = new RegExp(`(${query})`, 'i');
        return option?.c_hbc_MessageStarter?.match(regex) || option?.id?.match(regex);
      })
    },
    handleChange() {
      this.$emit('input', {
        id: this.product.product_id,
        disabledSentiments: this.selectedSentiments,
        disabledMessageStarters: this.selectedMessageStarters
      });
    }
  },
  mounted() {
    this.loading = true;
    this.selectedSentiments = this.product.disabledSentiments;
    this.selectedMessageStarters = this.product.disabledMessageStarters;
    this.fetchLibraryFolder({ folder_id: this.product.c_hbc_SentimentCategory })
      .then(response => {
        this.libraryItems = response.data.hits;
        this.loading = false;
      })
      .catch(error => {
        console.error(error);
        this.loading = false;
      })
  },
  watch: {
    selectedSentiments() {
      this.handleChange();
    },
    selectedMessageStarters() {
      this.handleChange();
    }
  }
}
</script>
