<template>
  <b-card
    no-body
    :border-variant="borderVariant"
    class="mb-3">
    <template v-slot:header v-if="profileName">
      <b-row align-v="center">
        <b-col>
          <div class="d-flex align-items-center">
            <b-button
              variant="link"
              class="d-flex align-items-center text-left"
              :disabled="editing"
              :aria-expanded="expanded ? 'true' : 'false'"
              :aria-controls="`profile-collapse-${profileID}`"
              @click="toggleCollapse"
            >
              <b-icon
                :icon="expanded ? 'chevron-up' : 'chevron-down'"
                :class="{ 'invisible' : editing }"></b-icon>
              <div class="h5 mb-0 ml-2">
                <strong>{{ profileName }}</strong>
              </div>
            </b-button>
          </div>
        </b-col>
        <div class="pr-3">
          <div class="w-100 d-flex justify-content-end">
            <b-button
              :class="{ 'invisible' : !editable }"
              :disabled="!editable"
              variant="secondary"
              @click.prevent="editProfile">Edit</b-button>
          </div>
        </div>
      </b-row>
    </template>
    <b-collapse
      :id="`profile-collapse-${profileID}`"
      v-model="expanded"
      @shown="handleExpanded">
      <b-card-body>
        <b-form>
          <b-row>
            <b-col lg="6">
              <b-form-group>
                <label for="profileID">Profile ID</label>
                <b-form-input
                  size="lg"
                  id="profileID"
                  name="profileID"
                  v-model="$v.profileID.$model"
                  :state="validateState('profileID')"
                  aria-describedby="profileID-live-feedback"
                  :disabled="saving"
                  :readonly="readonly || !isNew"
                  ref="profileID"
                >
                </b-form-input>
                <b-form-invalid-feedback id="profileID-live-feedback">
                  Please enter a profile ID.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group>
                <label for="profileName">Profile Name</label>
                <b-form-input
                  size="lg"
                  id="profileName"
                  name="profileName"
                  v-model="$v.profileName.$model"
                  :state="validateState('profileName')"
                  aria-describedby="profileName-live-feedback"
                  :disabled="saving"
                  :readonly="readonly"
                  ref="profileName"
                >
                </b-form-input>
                <b-form-invalid-feedback id="profileName-live-feedback">
                  Please enter a profile name.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group>
                <label for="axInvoiceAccount">AX Invoice Account</label>
                <b-form-input
                  size="lg"
                  id="axInvoiceAccount"
                  name="axInvoiceAccount"
                  v-model="$v.axInvoiceAccount.$model"
                  :state="validateState('axInvoiceAccount')"
                  aria-describedby="axInvoiceAccount-live-feedback"
                  :disabled="saving"
                  :readonly="readonly"
                >
                </b-form-input>
                <b-form-invalid-feedback id="axInvoiceAccount-live-feedback">
                  Please enter an AX invoice account.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group>
                <label for="signature">Signature</label>
                <b-form-textarea
                  size="lg"
                  id="signature"
                  name="signature"
                  v-model="$v.signature.$model"
                  :state="validateState('signature')"
                  aria-describedby="signature-live-feedback"
                  :disabled="saving"
                  :readonly="readonly"
                ></b-form-textarea>
                <b-form-invalid-feedback id="signature-live-feedback">
                  Please enter a signature.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group>
                <label for="specificLogo">Profile Specific Logo</label>
                <b-form-input
                  size="lg"
                  id="specificLogo"
                  name="specificLogo"
                  v-model="$v.specificLogo.$model"
                  :state="validateState('specificLogo')"
                  aria-describedby="specificLogo-live-feedback"
                  :disabled="saving"
                  :readonly="readonly"
                  ref="specificLogo"
                >
                </b-form-input>
                <b-form-invalid-feedback id="specificLogo-live-feedback">
                  Please enter a profile specific logo.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  switch
                  size="lg"
                  v-model="newUserDefault"
                  :disabled="saving || readonly">Enabled by default when creating new users</b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <div class="border p-4">
                <h6 class="text-uppercase"><strong>Return Address</strong></h6>
                <b-form-row>
                  <b-col>
                    <b-form-group>
                      <label for="name">Name</label>
                      <b-form-input
                        size="lg"
                        id="name"
                        name="name"
                        v-model="$v.returnAddress.name.$model"
                        :state="validateState('name', true)"
                        aria-describedby="name-live-feedback"
                        :disabled="saving"
                        :readonly="readonly"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback id="name-live-feedback">
                        Please enter a name.
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group>
                      <label for="address1">Address 1</label>
                      <b-form-input
                        size="lg"
                        id="address1"
                        name="address1"
                        v-model="$v.returnAddress.address1.$model"
                        :state="validateState('address1', true)"
                        aria-describedby="address1-live-feedback"
                        :disabled="saving"
                        :readonly="readonly"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback id="address1-live-feedback">
                        Please enter an address.
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group>
                      <label for="address2">Address 2</label>
                      <b-form-input
                        size="lg"
                        id="address2"
                        name="address2"
                        v-model="returnAddress.address2"
                        aria-describedby="address2-live-feedback"
                        :disabled="saving"
                        :readonly="readonly"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-form-row>
                <b-form-row>

                  <b-col>
                    <b-form-group>
                      <label for="city">City</label>
                      <b-form-input
                        size="lg"
                        id="city"
                        name="city"
                        v-model="$v.returnAddress.city.$model"
                        :state="validateState('city', true)"
                        aria-describedby="city-live-feedback"
                        :disabled="saving"
                        :readonly="readonly"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback id="city-live-feedback">
                        Please enter a city.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group>
                      <label for="state">State</label>
                      <b-form-input
                        size="lg"
                        id="state"
                        name="state"
                        v-model="$v.returnAddress.state.$model"
                        :state="validateState('state', true)"
                        aria-describedby="state-live-feedback"
                        :disabled="saving"
                        :readonly="readonly"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback id="state-live-feedback">
                        Please enter a state.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group>
                      <label for="zip">Zipcode</label>
                      <b-form-input
                        size="lg"
                        id="zip"
                        name="zip"
                        v-model="$v.returnAddress.zip.$model"
                        :state="validateState('zip', true)"
                        aria-describedby="zip-live-feedback"
                        :disabled="saving"
                        :readonly="readonly"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback id="zip-live-feedback">
                        Please enter a zipcode.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                </b-form-row>
              </div>
            </b-col>
          </b-row>

          <b-form-checkbox
            size="lg"
            id="hasSpecificDisclaimer"
            name="hasSpecificDisclaimer"
            :disabled="saving || readonly"
            v-model="hasSpecificDisclaimer"
            switch>Profile Specific Disclaimer?
          </b-form-checkbox>

          <div class="border p-4 mt-3" v-if="hasSpecificDisclaimer">
            <b-form-group class="mb-0">
              <template #label>
                <strong>Back Card Disclaimer</strong>
              </template>

              <b-form-group>
                <b-form-checkbox
                  switch
                  size="lg"
                  id="specificDisclaimerHasEs"
                  :disabled="saving || readonly"
                  v-model="specificDisclaimerHasEs">Separate Spanish Disclaimer?</b-form-checkbox>
              </b-form-group>

              <b-row>
                <b-col>
                  <b-form-group
                    label="Default"
                    label-for="specificDisclaimerDefault">
                    <b-form-textarea
                      size="lg"
                      id="specificDisclaimerDefault"
                      name="specificDisclaimerDefault"
                      :rows="specificDisclaimerHasEs ? 6 : 3"
                      :disabled="saving"
                      :readonly="readonly"
                      v-model="specificDisclaimerDefault">
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col v-if="specificDisclaimerHasEs">
                  <b-form-group
                    label="Spanish"
                    label-for="specificDisclaimerEs">
                    <b-form-textarea
                      size="lg"
                      id="specificDisclaimerEs"
                      name="specificDisclaimerEs"
                      type="text"
                      :rows="specificDisclaimerHasEs ? 6 : 3"
                      :disabled="saving"
                      :readonly="readonly"
                      v-model="specificDisclaimerEs">
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form-group>
          </div>
        </b-form>
      </b-card-body>
      <b-card-footer v-if="editing">
        <b-form-row>
          <b-col>
            <b-button
              size="lg"
              variant="primary"
              @click.prevent="save"
              :disabled="$v.$anyError || saving">
              <div class="d-flex align-items-center" v-if="saving">
                <b-spinner small></b-spinner>
                <div class="ml-2">Saving...</div>
              </div>
              <template v-else>
                {{ isNew ? 'Create Profile' : 'Save Profile' }}
              </template>
            </b-button>
            <b-button
              size="lg"
              variant="secondary"
              :disabled="saving"
              :readonly="readonly"
              @click.prevent="cancel"
              class="ml-3">
              Cancel
            </b-button>
            <b-button
              v-if="!isNew"
              size="lg"
              variant="link"
              class="text-danger ml-3 px-0"
              @click.prevent="deleteProfile">
              Delete
            </b-button>
          </b-col>
        </b-form-row>
      </b-card-footer>
    </b-collapse>
  </b-card>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

const addressSchema = {
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: 'US'
};

export default {
  name: 'SenderProfileForm',
  props: [
    'value',
    'readonly',
    'editing',
    'editable',
    'expanded',
    'saving',
    'isNew'
  ],
  mixins: [validationMixin],
  validations() {
    return {
      profileID: {
        required
      },
      profileName: {
        required
      },
      axInvoiceAccount: {
        required
      },
      signature: {
        required
      },
      specificLogo: {
      },
      returnAddress: {
        name: {
          required
        },
        address1: {
          required
        },
        city: {
          required
        },
        state: {
          required
        },
        zip: {
          required
        },
        country: {
          required
        }
      }
    }
  },
  data() {
    return {
      profileID: '',
      profileName: '',
      axInvoiceAccount: '',
      signature: '',
      newUserDefault: false,
      returnAddress: {
        ...addressSchema
      },
      specificLogo: '',
      hasSpecificDisclaimer: false,
      specificDisclaimerDefault: '',
      specificDisclaimerHasEs: false,
      specificDisclaimerEs: ''
    }
  },
  computed: {
    borderVariant() {
      if (this.editing) {
        return 'primary';
      }
      return null;
    },
  },
  methods: {
    validateState(name, isReturnAddressProperty) {
      if (isReturnAddressProperty) {
        const { $dirty, $error } = this.$v.returnAddress[name];
        return $dirty ? !$error : null;
      }
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      const {
        profileID,
        profileName,
        axInvoiceAccount,
        signature,
        returnAddress,
        newUserDefault,
        specificLogo,
        hasSpecificDisclaimer,
        specificDisclaimerDefault,
        specificDisclaimerHasEs,
        specificDisclaimerEs
      } = this;
      const data = {
        profileID,
        profileName,
        axInvoiceAccount,
        signature,
        returnAddress,
        newUserDefault
      };
      const specificDisclaimer = {
        default: specificDisclaimerDefault
      };
      if (specificLogo.length) {
        data.specificLogo = specificLogo;
      }
      if (specificDisclaimerHasEs) {
        specificDisclaimer.es = specificDisclaimerEs;
      }
      if (hasSpecificDisclaimer) {
        data.specificDisclaimer = specificDisclaimer;
      }
      this.$emit('input', data);
      this.$emit('save');
    },
    cancel() {
      this.reset();
      this.$emit('cancel');
    },
    toggleCollapse() {
      if (this.expanded) {
        this.collapse();
      } else {
        this.expand();
      }
    },
    expand() {
      this.$emit('expand');
    },
    collapse() {
      this.$emit('collapse');
    },
    editProfile() {
      this.$emit('edit');
    },
    deleteProfile() {
      this.$emit('delete');
    },
    handleExpanded() {
      if (this.editing) {
        if (this.isNew) {
          this.$refs.profileID.focus();
        } else {
          this.$refs.profileName.focus();
        }
      }
    },
    reset() {
      this.profileID = this.value?.profileID || '';
      this.profileName = this.value?.profileName || '';
      this.axInvoiceAccount = this.value?.axInvoiceAccount || '';
      this.signature = this.value?.signature || '';
      this.returnAddress = this.value?.returnAddress || { ...addressSchema };
      this.newUserDefault = this.value?.newUserDefault || false;
      this.specificLogo = this.value?.specificLogo || '';
      this.hasSpecificDisclaimer = (this.value && Object.hasOwn(this.value, 'specificDisclaimer')) || false;
      this.specificDisclaimerDefault = this.value?.specificDisclaimer?.default || '';
      this.specificDisclaimerHasEs = (this.value?.specificDisclaimer && Object.hasOwn(this.value?.specificDisclaimer, 'es')) || false;
      this.specificDisclaimerEs = this.value?.specificDisclaimer?.es || '';
      this.$v.$reset();
    },
  },
  watch: {
    value() {
      this.reset();
    },
    editing(val) {
      if (val) {
        this.$nextTick(() => {
          if (this.isNew) {
            this.$refs.profileID.focus();
          } else {
            this.$refs.profileName.focus();
          }
        })
      }
    }
  },
  mounted() {
    this.reset();
    if (this.isNew) {
      this.$refs.profileID.focus();
    } else {
      this.$refs.profileName.focus();
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control[readonly] {
  border-color: transparent !important;
  background-color: transparent !important;
}
</style>
