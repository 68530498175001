<template>
  <b-form @submit.prevent="onSubmit">
    <b-form-row>
      <b-col>
        <b-spinner v-if="loading"></b-spinner>
        <b-card-body v-if="!loading">
          <b-button
            v-if="!selectedProducts.length && productsNotInModelLine.length"
            size="lg"
            variant="primary"
            block
            v-b-modal.add-products>Add Products</b-button>
          <b-button
            v-if="selectedProducts.length"
            size="lg"
            variant="danger"
            block
            @click.prevent="confirmRemoveProducts">Remove Products</b-button>
        </b-card-body>
        <div v-if="productsInModelLine.length" class="p-3 bg-light">
          <b-form-row>
            <b-col>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search SKU"
              ></b-form-input>
            </b-col>
            <b-col>
              <b-form-select
                v-model="modelLine"
                :options="modelLineOptions"
                :disabled="modelLineOptions.length === 1"
              ></b-form-select>
            </b-col>
          </b-form-row>
        </div>
        <b-table
          class="card-table"
          show-empty
          hover
          :fields="tableFields"
          :items="filteredProductsInModelLine"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          responsive
          :busy="tableBusy"
          ref="productTable"
          empty-text="No products currently assigned."
          selectable
          @row-selected="onRowSelected"
          >
          <template #head(selected)>
            <b-icon
              v-if="productsInModelLine.length"
              :icon="allRowsSelected ? 'check-square-fill' : 'square'"
              @click.prevent="toggleSelectAllRows" />
            <span v-if="productsInModelLine.length" class="sr-only">{{ allRowsSelected ? 'Deselect all' : 'Select all' }}</span>
          </template>

          <template #cell(selected)="{ rowSelected }">
            <b-icon :icon="rowSelected ? 'check-square-fill' : 'square'"></b-icon>
            <span class="sr-only">{{ rowSelected ? 'Selected' : 'Not Selected' }}</span>
          </template>

          <template #cell(thumbnail)="data">
            <b-avatar
              rounded
              :src="getProductThumbnail(data.item.product_id)"
              variant="white"></b-avatar>
          </template>
          <template #cell(actions)="data">
            <b-button @click.prevent="configureProduct(data.item)">Configure</b-button>
          </template>
        </b-table>
        <b-card-body v-if="!loading">
          <b-button
            v-if="!selectedProducts.length && productsNotInModelLine.length"
            size="lg"
            variant="primary"
            block
            v-b-modal.add-products>Add Products</b-button>
          <b-button
            v-if="selectedProducts.length"
            size="lg"
            variant="danger"
            block
            @click.prevent="confirmRemoveProducts">Remove Products</b-button>
        </b-card-body>
      </b-col>
    </b-form-row>

    <b-modal
      size="lg"
      id="add-products"
      body-class="p-0"
      title="Select Products"
      @hidden="resetAddProducts">
      <SelectProducts
        v-model="productsToAdd"
        :products="productsNotInModelLine"
        tableClass="card-table"
      />

      <template #modal-footer="{ cancel }">
        <div class="w-100 d-flex justify-content-end">
          <b-button
            variant="secondary"
            @click.prevent="cancel()"
            :disabled="saving">
            Cancel
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            @click.prevent="addProductsToModelLine()"
            :disabled="saving || !productsToAdd.length">
            <div class="d-flex align-items-center" v-if="saving">
              <b-spinner small></b-spinner>
              <div class="ml-2">Saving...</div>
            </div>
            <template v-else>
              Add products to model line
            </template>
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      :title="productBeingConfiguredName"
      size="lg"
      id="configure-product"
      @hidden="resetConfigureProduct">
      <ConfigureClientModelLineProduct
        v-model="newProductConfiguration"
        :product="productBeingConfigured"
      />

      <template #modal-footer="{ cancel }">
        <div class="w-100 d-flex justify-content-end">
          <b-button
            variant="secondary"
            :disabled="saving"
            @click.prevent="cancel()">
            Cancel
          </b-button>
          <b-button
            class="ml-2"
            variant="primary"
            :disabled="saving || !newProductConfiguration"
            @click.prevent="updateProductInModelLine()">
            <div class="d-flex align-items-center" v-if="saving">
              <b-spinner small></b-spinner>
              <div class="ml-2">Saving...</div>
            </div>
            <template v-else>
              Save Product Configuration
            </template>
          </b-button>
        </div>
      </template>
    </b-modal>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex';
import SelectProducts from '@/components/clients/selectProducts.vue';
import ConfigureClientModelLineProduct from '@/components/clients/configureClientModelLineProduct.vue';

export default {
  name: 'EditClientModelLineForm',
  props: [
    'errors',
    'clientModelLine',
    'save'
  ],
  components: {
    SelectProducts,
    ConfigureClientModelLineProduct
  },
  data() {
    return {
      searchQuery: '',
      modelLine: '',
      selectedProducts: [],
      selectMode: 'multi',
      tableFields: [
        { key: 'selected', sortable: false, tdClass: 'align-middle td-shrink' },
        { key: 'thumbnail', sortable: false, tdClass: 'align-middle td-shrink text-center' },
        { key: 'product_id', label: 'ID', sortable: true, tdClass: 'align-middle td-shrink' },
        { key: 'product_name', label: 'Name', sortable: true, tdClass: 'align-middle' },
        { key: 'actions', label: 'Actions', tdClass: 'py-0 align-middle td-shrink' }
      ],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      saving: false,
      loading: true,
      currentlyEditing: '',
      products: [],
      productsToAdd: [],
      productBeingConfigured: null,
      newProductConfiguration: null,
      form: {
        clientModelLine: []
      }
    }
  },
  computed: {
    tableBusy() {
      return this.loading;
    },
    selectedProductsObject() {
      const obj = {};
      this.selectedProducts.forEach(item => {
        obj[item.product_id] = null;
      });
      return obj;
    },
    modelLineObject() {
      const obj = {};
      if (this.form.clientModelLine.length) {
        this.form.clientModelLine.forEach(product => {
          obj[product.id] = {
            ...product
          };
        })
      }
      return obj;
    },
    productsObject() {
      const obj = {};
      if (this.products.length) {
        this.products.forEach(product => {
          obj[product.product_id] = {
            ...product
          };
        })
      }
      return obj;
    },
    allRowsSelected() {
      return this.selectedProducts.length === this.productsInModelLine.length;
    },
    mergedProducts() {
      return this.products.map(product => {
        if (product.product_id in this.modelLineObject) {
          return {
            ...product,
            disabledSentiments: this.modelLineObject[product.product_id].disabledSentiments,
            disabledMessageStarters: this.modelLineObject[product.product_id].disabledMessageStarters,
          }
        }
        return {
          ...product,
          disabledSentiments: [],
          disabledMessageStarters: []
        }
      })
    },
    productsInModelLine() {
      return this.mergedProducts.filter(product => product.product_id in this.modelLineObject);
    },
    filteredProductsInModelLine() {
      if (!this.searchQuery.length && this.modelLine === '') {
        return this.productsInModelLine;
      }
      return this.productsInModelLine.filter(product => {
        if (!this.productMatchesModelLine(product)) {
          return false;
        }
        return product.product_id.includes(this.searchQuery);
      });
    },
    modelLineOptions() {
      const modelLines = this.productsInModelLine.reduce((acc, product) => {
        return product?.c_hbc_ModelLine?.length ? [...acc, ...product.c_hbc_ModelLine] : [...acc];
      }, []);
      const modelLineValues = modelLines.map(modelLine => modelLine.value);
      return [
        {
          value: '',
          label: 'Filter by model line'
        },
        // remove duplicates
        ...modelLines.filter(({ value }, index) => !modelLineValues.includes(value, index + 1))
      ].map(line => ({ value: line.value, text: line.label }));
    },
    productsNotInModelLine() {
      return this.mergedProducts.filter(product => !(product.product_id in this.modelLineObject));
    },
    productBeingConfiguredName() {
      if (this.productBeingConfigured?.product_name) {
        return `${this.productBeingConfigured?.product_id} | ${this.productBeingConfigured?.product_name}`
      }
      return '';
    }
  },
  methods: {
    ...mapActions('ocapi', [
      'fetchAllProducts',
    ]),
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    ...mapActions('ocapi', [
      'updateCustomerGroup'
    ]),
    getProductTitle(id) {
      return id in this.productsObject ? this.productsObject[id].product_name : '';
    },
    getProductSentimentCategory(id) {
      return id in this.productsObject ? this.productsObject[id].c_hbc_SentimentCategory : '';
    },
    getProductThumbnail(id) {
      return id in this.productsObject ? this.productsObject[id]?.image?.link : '';
    },
    getProductsForSave() {
      return this.form.clientModelLine.map(modelLineObject => ({ ...modelLineObject.product }));
    },
    productExists(id) {
      return id in this.productsObject;
    },
    configureProduct(product) {
      this.productBeingConfigured = product;
      this.$nextTick(() => {
        this.$bvModal.show('configure-product')
      })
    },
    selectAllRows() {
      this.$refs.productTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.productTable.clearSelected();
      this.searchQuery = '';
      this.modelLine = '';
    },
    onRowSelected(items) {
      this.selectedProducts = items;
    },
    toggleSelectAllRows() {
      if (this.allRowsSelected) {
        this.clearSelected();
      } else {
        this.selectAllRows();
      }
    },
    confirmRemoveProducts() {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to remove these products?\n\n${this.selectedProducts.map(product => product.product_id).join(', ')}`, {
        title: 'Confirm remove products',
        // size: 'sm',
        // buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        // footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.removeProductsFromModelLine();
          }
        })
    },
    removeProductsFromModelLine() {
      this.saving = true;
      const newModelLine = [
        ...this.form.clientModelLine.filter(product => {
          return !(product.id in this.selectedProductsObject);
        })
      ];
      this.save(newModelLine)
        .then(() => {
          this.saving = false;
          this.$bvToast.toast('Model line was updated successfully', {
            title: 'Changes saved',
            autoHideDelay: 2500,
            variant: 'success',
            toaster: 'b-toaster-top-center'
          });
          this.clearSelected();
        })
        .catch(() => {
          this.$bvToast.toast('There was a problem saving your changes. Please try again or contact support.', {
            title: 'Error',
            variant: 'error',
            toaster: 'b-toaster-top-center'
          });
        });
    },
    resetAddProducts() {
      this.productsToAdd = [];
    },
    addProductsToModelLine() {
      this.saving = true;
      const newModelLine = [
        ...this.form.clientModelLine,
        ...this.productsToAdd
          .filter(product => !(product.product_id in this.modelLineObject))
          .map(product => ({
            id: product.product_id,
            disabledSentiments: [],
            disabledMessageStarters: [],
          }))
      ];
      this.save(newModelLine)
        .then(response => {
          this.saving = false;
          this.$nextTick(() => {
            this.$bvModal.hide('add-products')
            this.$bvToast.toast('Model line was updated successfully', {
              title: 'Changes saved',
              autoHideDelay: 2500,
              variant: 'success',
              toaster: 'b-toaster-top-center'
            });
          });
        })
        .catch(error => {
          this.saving = false;
          this.$nextTick(() => {
            this.$bvToast.toast('There was a problem saving your changes. Please try again or contact support.', {
              title: 'Error',
              variant: 'error',
              toaster: 'b-toaster-top-center'
            });
          });
          console.log(error)
        })
    },
    resetConfigureProduct() {
      this.productBeingConfigured = null;
      this.newProductConfiguration = null;
    },
    updateProductInModelLine() {
      this.saving = true;
      const newModelLine = [
        ...this.form.clientModelLine.map(product => {
          if (product.id === this.newProductConfiguration.id) {
            return this.newProductConfiguration;
          }
          return product;
        })
      ];
      this.save(newModelLine)
        .then(() => {
          this.saving = false;
          this.$nextTick(() => {
            this.$bvModal.hide('configure-product')
            this.$bvToast.toast('Model line was updated successfully', {
              title: 'Changes saved',
              autoHideDelay: 2500,
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
          })
        })
        .catch(() => {
          this.saving = false;
          this.$nextTick(() => {
            this.$bvToast.toast('There was a problem saving your changes. Please try again or contact support.', {
              title: 'Error',
              variant: 'error',
              toaster: 'b-toaster-top-center'
            });
          });
        });
    },
    initializeForm() {
      this.startLoading();
      this.fetchAllProducts()
        .then(products => {
          console.log({ products });
          this.products = products;
          this.form = {
            clientModelLine: this.clientModelLine
          };
          console.log(this.form);
          this.stopLoading();
        })
        .catch(error => {
          this.stopLoading();
          console.error(error);
        });
    },
    productMatchesModelLine(product) {
      if (this.modelLine === '') {
        return true;
      }
      if (!product?.c_hbc_ModelLine?.length) {
        return false;
      }
      return product.c_hbc_ModelLine.map(line => line.value).includes(this.modelLine);
    }
  },
  watch: {
    clientModelLine() {
      this.initializeForm();
    },
  },
  mounted() {
    this.initializeForm();
  }
}
</script>

<style lang="scss">
  #configure-product .modal-body,
  #add-products .modal-body {
    max-height: 80vh; /* max 80% of the viewport height */
    overflow-y: auto;
  }
</style>
