<template>
  <b-form @submit.prevent="onSubmit">

    <b-card
      header-bg-variant="light"
      no-body>
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0"><strong>Client Minimum</strong></h5>
          <b-form-checkbox
            size="lg"
            id="clientMinimumEnabled"
            name="clientMinimumEnabled"
            v-model="form.clientMinimumEnabled"
            class="mr-n3 mt-n1"
            v-b-tooltip.hover="form.clientMinimumEnabled ? 'Disable' : 'Enable'"
            switch>
          </b-form-checkbox>
        </div>
      </template>
      <b-card-body v-if="form.clientMinimumEnabled">
        <b-row>
          <b-col>
            <b-form-group>
              <label for="clientMinimumValue">Value</label>
              <b-form-input
                size="lg"
                id="clientMinimumValue"
                name="clientMinimumValue"
                type="number"
                v-model="$v.form.clientMinimumValue.$model"
                :state="validateState('clientMinimumValue')"
                aria-describedby="clientMinimumValue-live-feedback"
              >
              </b-form-input>
              <b-form-invalid-feedback id="clientMinimumValue-live-feedback">
                Please enter a client minimum.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="clientMinimumStartDate">Start Date</label>
              <b-form-datepicker
                size="lg"
                id="clientMinimumStartDate"
                name="clientMinimumStartDate"
                type="number"
                v-model="$v.form.clientMinimumStartDate.$model"
                :state="validateState('clientMinimumStartDate')"
                aria-describedby="clientMinimumStartDate-live-feedback"
              >
              </b-form-datepicker>
              <b-form-invalid-feedback id="clientMinimumStartDate-live-feedback">
                Please select a start date.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="clientMinimumEndDate">End Date</label>
              <b-form-datepicker
                size="lg"
                id="clientMinimumEndDate"
                name="clientMinimumEndDate"
                type="number"
                v-model="$v.form.clientMinimumEndDate.$model"
                :state="validateState('clientMinimumEndDate')"
                aria-describedby="clientMinimumEndDate-live-feedback"
              >
              </b-form-datepicker>
              <b-form-invalid-feedback id="clientMinimumEndDate-live-feedback">
                Please select a end date.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

      </b-card-body>
    </b-card>

    <b-card
      header-bg-variant="light"
      no-body>
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0"><strong>Account Number</strong></h5>
          <b-form-checkbox
            size="lg"
            id="accountNumberEnabled"
            name="accountNumberEnabled"
            v-model="form.accountNumberEnabled"
            class="mr-n3 mt-n1"
            v-b-tooltip.hover="form.accountNumberEnabled ? 'Disable' : 'Enable'"
            switch>
          </b-form-checkbox>
        </div>
      </template>
      <b-card-body v-if="form.accountNumberEnabled">
        <b-row>
          <b-col>
            <b-form-group>
              <label for="accountNumberLabel">Label</label>
              <b-form-input
                size="lg"
                id="accountNumberLabel"
                name="accountNumberLabel"
                v-model="form.accountNumberLabel"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="accountNumberMaxLength">Max length</label>
              <b-form-input
                size="lg"
                id="accountNumberMaxLength"
                name="accountNumberMaxLength"
                type="number"
                v-model="form.accountNumberMaxLength"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group>
          <b-form-checkbox
            size="lg"
            id="accountNumberRequired"
            name="accountNumberRequired"
            v-model="form.accountNumberRequired"
            switch
          >Required
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            size="lg"
            id="accountNumberNumeric"
            name="accountNumberNumeric"
            v-model="form.accountNumberNumeric"
            switch
          >Numeric
          </b-form-checkbox>
        </b-form-group>
      </b-card-body>
    </b-card>

    <b-card
      header-bg-variant="light"
      no-body>
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0"><strong>Locations ({{ form.locations.length }})</strong></h5>
          <b-button class="my-n2" variant="outline-secondary" size="sm" @click.prevent="addNewLocation">Add Location</b-button>
        </div>
      </template>
      <b-list-group flush class="locations">
        <b-list-group-item
          v-for="(location, index) in sortedLocations"
          class="py-1"
          :key="location.value">
          <div class="d-flex w-100 justify-content-between align-items-center">
            {{ location.value }}
            <div class="d-flex align-items-center">
              <b-badge pill variant="success" v-if="'default' in location && location.default == true">Default</b-badge>
              <b-dropdown right size="sm" text="Small" class="m-2" variant="link" no-caret>
                <template #button-content>
                  <b-icon icon="three-dots" variant="secondary"></b-icon><span class="sr-only">Actions</span>
                </template>
                <b-dropdown-item @click.prevent="editLocation(location, index)">Edit</b-dropdown-item>
                <b-dropdown-item @click.prevent="makeLocationDefault(location)">Make Default</b-dropdown-item>
                <b-dropdown-item-button @click.prevent="deleteLocation(location)">Delete</b-dropdown-item-button>
              </b-dropdown>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>

    <b-card
      header-bg-variant="light"
      no-body>
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0"><strong>Tax Exempt</strong></h5>
          <b-form-checkbox
            size="lg"
            id="taxExemptEnabled"
            name="taxExemptEnabled"
            v-model="form.taxExemptEnabled"
            class="mr-n3 mt-n1"
            v-b-tooltip.hover="form.taxExemptEnabled ? 'Disable' : 'Enable'"
            switch>
          </b-form-checkbox>
        </div>
      </template>
      <b-card-body v-if="form.taxExemptEnabled">
        <b-form-group>
          <label for="taxExemptCode">Exempt Code</label>
          <b-form-select
            size="md"
            id="taxExemptCode"
            name="taxExemptCode"
            v-model="$v.form.taxExemptCode.$model"
            :options="exemptCodeOptions"
            :state="validateState('taxExemptCode')"
            aria-describedby="taxExemptCode-live-feedback"
          >
          </b-form-select>
          <b-form-invalid-feedback id="taxExemptCode-live-feedback">
            Please select a tax exempt code.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <label for="taxExemptStates">Exempted States</label>
          <b-form-select
            size="md"
            id="taxExemptStates"
            name="taxExemptStates"
            v-model="$v.form.taxExemptStates.$model"
            :options="stateOptions"
            multiple
            :select-size="8"
            :state="validateState('taxExemptStates')"
            aria-describedby="taxExemptStates-live-feedback"
          >
          </b-form-select>
          <b-form-invalid-feedback id="taxExemptStates-live-feedback">
            Please select a state.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-card-body>
    </b-card>

    <b-modal
      id="edit-location"
      :title="addingNewLocation ? 'Add New Location' : 'Edit Location'"
      @ok.prevent="onEditLocationSubmit"
      @hidden="resetEditLocation"
      @shown="$refs.location.focus()">
      <b-form v-if="locationBeingEdited" @submit.prevent="onEditLocationSubmit">
        <b-form-group>
          <label for="location">Value</label>
          <b-form-input
            size="lg"
            v-model="locationBeingEdited.value"
            id="location"
            name="location"
            :state="locationValid"
            aria-described-by="location-live-feedback"
            ref="location"></b-form-input>
          <b-form-invalid-feedback id="location-live-feedback">
            {{ locationFeedback }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox v-model="locationBeingEdited.default">Default Location</b-form-checkbox>
        </b-form-group>
        <button class="d-none" type="submit"></button>
      </b-form>

      <template #modal-ok>{{ addingNewLocation ? 'Add' : 'Update' }}</template>
    </b-modal>

    <b-card
      header-bg-variant="light"
      no-body>
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0"><strong>Back Card Content</strong></h5>
          <b-form-checkbox
            size="lg"
            id="enableBackCardContent"
            name="enableBackCardContent"
            v-model="form.enableBackCardContent"
            class="mr-n3 mt-n1"
            v-b-tooltip.hover="form.enableBackCardContent ? 'Disable' : 'Enable'"
            switch>
          </b-form-checkbox>
        </div>
      </template>
      <b-list-group flush v-if="form.enableBackCardContent">
        <b-list-group-item>
          <b-form-group>
            <label for="backCardMessage">Back Card Message</label>
            <b-form-textarea
              size="lg"
              id="backCardMessage"
              name="backCardMessage"
              type="text"
              v-model="form.backCardMessage"
            >
            </b-form-textarea>
          </b-form-group>
        </b-list-group-item>

        <b-list-group-item>

          <b-form-group>

            <template #label>
              <strong>Back Card Disclaimer</strong>
            </template>

            <b-form-group>
              <b-form-checkbox
                switch
                size="lg"
                id="backCardDisclaimerHasEs"
                v-model="form.backCardDisclaimerHasEs">Separate Spanish Disclaimer?</b-form-checkbox>
            </b-form-group>

            <b-row>
              <b-col>
                <b-form-group
                  label="Default"
                  label-for="backCardDisclaimerDefault">
                  <b-form-textarea
                    size="lg"
                    id="backCardDisclaimerDefault"
                    name="backCardDisclaimerDefault"
                    type="text"
                    :rows="form.backCardDisclaimerHasEs ? 6 : 3"
                    v-model="form.backCardDisclaimerDefault">
                  </b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col v-if="form.backCardDisclaimerHasEs">
                <b-form-group
                  label="Spanish"
                  label-for="backCardDisclaimerEs">
                  <b-form-textarea
                    size="lg"
                    id="backCardDisclaimerEs"
                    name="backCardDisclaimerEs"
                    type="text"
                    :rows="form.backCardDisclaimerHasEs ? 6 : 3"
                    v-model="form.backCardDisclaimerEs">
                  </b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>

          </b-form-group>
        </b-list-group-item>

      </b-list-group>
    </b-card>

    <b-form-group>
      <b-form-checkbox
        switch
        size="lg"
        id="defaultNewUserTypeSSO"
        v-model="form.defaultNewUserTypeSSO">Default new user type SSO?</b-form-checkbox>
    </b-form-group>

    <b-form-group>
      <b-form-checkbox
        switch
        size="lg"
        id="singleSendSSO"
        v-model="form.singleSendSSO">End user session on app close?*<br><sub>*Enable if client passes us Recipient Data in SAML Assertion</sub></b-form-checkbox>
    </b-form-group>

    <b-form-group>
      <b-form-checkbox
        switch
        size="lg"
        id="disableAutoProvision"
        v-model="form.disableAutoProvision">Disable Auto Provision during SSO?</b-form-checkbox>
    </b-form-group>

    <b-form-group>
      <b-form-checkbox
        switch
        size="lg"
        id="disableEditPersonalClosing"
        v-model="form.disableEditPersonalClosing">Disable ability to edit personal closing?</b-form-checkbox>
    </b-form-group>

    <b-button
      variant="primary"
      size="lg"
      class="mt-5"
      block
      type="submit">Save</b-button>

  </b-form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'EditClientConfigForm',
  props: [
    'errors',
    'clientConfig'
  ],
  mixins: [validationMixin],
  validations() {
    const validation = {
      form: {
      }
    }
    if (this.form.clientMinimumEnabled) {
      validation.form.clientMinimumValue = {
        required,
      }
      validation.form.clientMinimumStartDate = {
        required,
      }
      validation.form.clientMinimumEndDate = {
        required,
      }
    }
    if (this.form.taxExemptEnabled) {
      validation.form.taxExemptCode = {
        required,
      }
      validation.form.taxExemptStates = {
        required,
      }
    }
    return validation
  },
  data() {
    return {
      addingNewLocation: false,
      indexOflocationBeingEdited: null,
      locationBeingEdited: null,
      locationBeingEditedPreviousValue: null,
      showLocationValidation: false,
      form: {
        locations: [],
        defaultNewUserTypeSSO: false,
        singleSendSSO: false,
        disableAutoProvision: false,
        axCustomFields: [],
        clientMinimumEnabled: '',
        clientMinimumValue: '',
        clientMinimumStartDate: '',
        clientMinimumEndDate: '',
        accountNumberLabel: '',
        accountNumberValue: '',
        accountNumberRequired: '',
        accountNumberEnabled: '',
        accountNumberNumeric: '',
        accountNumberMaxLength: '',
        disableEditPersonalClosing: false,
        taxExemptEnabled: '',
        taxExemptCode: '',
        taxExemptStates: '',
        enableBackCardContent: false,
        backCardMessage: '',
        backCardDisclaimerDefault: '',
        backCardDisclaimerHasEs: false,
        backCardDisclaimerEs: ''
      },
      exemptCodeOptions: [
        { value: '', text: 'Select Exempt Code' },
        { value: 'RESELLER', text: 'RESELLER' },
        { value: 'EXEMPT NONRESELLER', text: 'EXEMPT NONRESELLER' },
      ],
      stateOptions: [
        { value: 'AL', text: 'Alabama' },
        { value: 'AK', text: 'Alaska' },
        { value: 'AZ', text: 'Arizona' },
        { value: 'AR', text: 'Arkansas' },
        { value: 'CA', text: 'California' },
        { value: 'CO', text: 'Colorado' },
        { value: 'CT', text: 'Connecticut' },
        { value: 'DE', text: 'Delaware' },
        { value: 'FL', text: 'Florida' },
        { value: 'GA', text: 'Georgia' },
        { value: 'HI', text: 'Hawaii' },
        { value: 'ID', text: 'Idaho' },
        { value: 'IL', text: 'Illinois' },
        { value: 'IN', text: 'Indiana' },
        { value: 'IA', text: 'Iowa' },
        { value: 'KS', text: 'Kansas' },
        { value: 'KY', text: 'Kentucky' },
        { value: 'LA', text: 'Louisiana' },
        { value: 'ME', text: 'Maine' },
        { value: 'MD', text: 'Maryland' },
        { value: 'MA', text: 'Massachusetts' },
        { value: 'MI', text: 'Michigan' },
        { value: 'MN', text: 'Minnesota' },
        { value: 'MO', text: 'Missouri' },
        { value: 'MS', text: 'Mississippi' },
        { value: 'MT', text: 'Montana' },
        { value: 'NE', text: 'Nebraska' },
        { value: 'NV', text: 'Nevada' },
        { value: 'NH', text: 'New Hampshire' },
        { value: 'NJ', text: 'New Jersey' },
        { value: 'NM', text: 'New Mexico' },
        { value: 'NY', text: 'New York' },
        { value: 'NC', text: 'North Carolina' },
        { value: 'ND', text: 'North Dakota' },
        { value: 'OH', text: 'Ohio' },
        { value: 'OK', text: 'Oklahoma' },
        { value: 'OR', text: 'Oregon' },
        { value: 'PA', text: 'Pennsylvania' },
        { value: 'RI', text: 'Rhode Island' },
        { value: 'SC', text: 'South Carolina' },
        { value: 'SD', text: 'South Dakota' },
        { value: 'TN', text: 'Tennessee' },
        { value: 'TX', text: 'Texas' },
        { value: 'UT', text: 'Utah' },
        { value: 'VT', text: 'Vermont' },
        { value: 'VA', text: 'Virginia' },
        { value: 'WA', text: 'Washington' },
        { value: 'DC', text: 'Washington DC' },
        { value: 'WV', text: 'West Virginia' },
        { value: 'WI', text: 'Wisconsin' },
        { value: 'WY', text: 'Wyoming' },
        { value: 'GU', text: 'Guam' },
        { value: 'PR', text: 'Puerto Rico' },
        { value: 'VI', text: 'Virgin Islands' },
        { value: 'MP', text: 'Northern Mariana Islands' },
        { value: 'AS', text: 'American Samoa' },
        { value: 'MH', text: 'Marshall Islands' },
        { value: 'PW', text: 'Palau' },
        { value: 'AE', text: 'AE - Armed Forces Europe, the Middle East, Canada', },
        { value: 'AP', text: 'AP - Armed Forces Pacific' },
        { value: 'AA', text: 'AA - Armed Forces America (except Canada)' },
      ],
    }
  },
  computed: {
    locations() {
      const values = [];
      this.form.locations.forEach(location => {
        values.push(location.value);
      });
      return values;
    },
    sortedLocations() {
      return [...this.form.locations].sort((a, b) => {
        const textA = a.value.toUpperCase();
        const textB = b.value.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    },
    locationFeedback() {
      if (!this.locationBeingEdited?.value?.trim()?.length) {
        return 'Value is required';
      }
      if (this.locations.find(location => this.locationBeingEdited?.value === location) && this.locationBeingEditedPreviousValue !== this.locationBeingEdited?.value) {
        return 'This value is already in use by another location';
      }
      return '';
    },
    locationValid() {
      if (!this.showLocationValidation) {
        return null;
      }
      if (!this.locationBeingEdited?.value?.trim()?.length) {
        return false;
      }
      if (this.locationBeingEditedPreviousValue !== this.locationBeingEdited?.value) {
        return this.locations.find(location => this.locationBeingEdited?.value === location) === undefined;
      }
      return true;
    },
  },
  methods: {
    initializeForm() {
      const form = {
        locations: this.clientConfig?.locations || [],
        defaultNewUserTypeSSO: this.clientConfig?.defaultNewUserTypeSSO || false,
        singleSendSSO: this.clientConfig?.singleSendSSO || false,
        disableAutoProvision: this.clientConfig?.disableAutoProvision || false,
        axCustomFields: this.clientConfig?.customFields?.axCustomFields || [],
        clientMinimumEnabled: this.clientConfig?.customFields?.clientMinimum?.enabled || false,
        clientMinimumValue: parseInt(this.clientConfig?.customFields?.clientMinimum?.value, 10) || 0,
        clientMinimumStartDate: this.clientConfig?.customFields?.clientMinimum?.startDate || '',
        clientMinimumEndDate: this.clientConfig?.customFields?.clientMinimum?.endDate || '',
        accountNumberLabel: this.clientConfig?.customFields?.accountNumber?.label || '',
        accountNumberValue: this.clientConfig?.customFields?.accountNumber?.value || '',
        accountNumberRequired: this.clientConfig?.customFields?.accountNumber?.required || false,
        accountNumberEnabled: this.clientConfig?.customFields?.accountNumber?.enabled || false,
        accountNumberNumeric: this.clientConfig?.customFields?.accountNumber?.numeric || false,
        accountNumberMaxLength: parseInt(this.clientConfig?.customFields?.accountNumber?.maxLength, 10) || 0,
        disableEditPersonalClosing: this.clientConfig?.disableEditPersonalClosing || false,
        taxExemptEnabled: this.clientConfig?.customFields?.taxExempt?.enabled || false,
        taxExemptCode: this.clientConfig?.customFields?.taxExempt?.code || '',
        taxExemptStates: this.clientConfig?.customFields?.taxExempt?.states || [],
        enableBackCardContent: this.clientConfig?.enableBackCardContent || false,
        backCardMessage: this.clientConfig?.backCardMessage || '',
        backCardDisclaimerDefault: this.clientConfig?.backCardDisclaimer?.default || '',
        backCardDisclaimerHasEs: (this.clientConfig?.backCardDisclaimer && Object.hasOwn(this.clientConfig?.backCardDisclaimer, 'es')) || false,
        backCardDisclaimerEs: this.clientConfig?.backCardDisclaimer?.es || ''
      };
      console.log(form);
      this.form = form;
    },
    addNewLocation() {
      this.addingNewLocation = true;
      this.editLocation();
    },
    editLocation(location, index) {
      this.indexOflocationBeingEdited = index;
      this.locationBeingEdited = Object.assign({}, { value: '', default: false }, location);
      this.locationBeingEditedPreviousValue = this.locationBeingEdited.value;
      this.$nextTick(() => {
        this.$bvModal.show('edit-location');
      });
    },
    makeLocationDefault(newDefaultLocation) {
      this.form.locations = [
        ...this.form.locations.map(location => {
          const newLocation = {
            value: location.value,
          };
          if (location.value === newDefaultLocation.value) {
            newLocation.default = true;
          }
          return newLocation;
        })
      ]
    },
    deleteLocation(locationToDelete) {
      this.form.locations = [
        ...this.form.locations.filter(location => location.value !== locationToDelete.value)
      ]
    },
    onEditLocationSubmit() {
      this.showLocationValidation = true;
      if (this.locationValid) {
        let newLocations = [
          ...this.form.locations
        ];
        if (this.addingNewLocation) {
          newLocations.push(this.locationBeingEdited);
        } else {
          newLocations[this.indexOflocationBeingEdited] = this.locationBeingEdited;
        }
        if (this.locationBeingEdited.default) {
          newLocations = newLocations.map(location => {
            if (location.value !== this.locationBeingEdited.value) {
              return {
                value: location.value
              }
            }
            return location;
          })
        }
        this.form.locations = newLocations;
        this.$nextTick(() => {
          this.$bvModal.hide('edit-location');
        });
      }
    },
    resetEditLocation() {
      this.addingNewLocation = false;
      this.locationBeingEdited = null;
      this.locationBeingEditedPreviousValue = null;
      this.showLocationValidation = false;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        console.log(this.$v.form);
        return;
      }
      this.$v.$reset();
      const {
        locations,
        defaultNewUserTypeSSO,
        singleSendSSO,
        disableAutoProvision,
        axCustomFields,
        clientMinimumEnabled,
        clientMinimumValue,
        clientMinimumStartDate,
        clientMinimumEndDate,
        accountNumberLabel,
        accountNumberValue,
        accountNumberRequired,
        accountNumberEnabled,
        accountNumberNumeric,
        accountNumberMaxLength,
        disableEditPersonalClosing,
        taxExemptEnabled,
        taxExemptCode,
        taxExemptStates,
        enableBackCardContent,
        backCardMessage,
        backCardDisclaimerDefault,
        backCardDisclaimerHasEs,
        backCardDisclaimerEs
      } = this.form;

      const backCardDisclaimer = {
        default: backCardDisclaimerDefault
      };
      if (backCardDisclaimerHasEs) {
        backCardDisclaimer.es = backCardDisclaimerEs;
      }
      this.$emit('submit', {
        locations,
        defaultNewUserTypeSSO,
        singleSendSSO,
        disableAutoProvision,
        enableBackCardContent,
        backCardMessage,
        backCardDisclaimer,
        customFields: {
          axCustomFields,
          accountNumber: {
            label: accountNumberLabel,
            value: accountNumberValue,
            enabled: accountNumberEnabled,
            required: accountNumberRequired,
            numeric: accountNumberNumeric,
            maxLength: accountNumberMaxLength || 0
          },
          clientMinimum: {
            enabled: clientMinimumEnabled,
            value: clientMinimumValue || 0,
            startDate: clientMinimumStartDate,
            endDate: clientMinimumEndDate
          },
          taxExempt: {
            enabled: taxExemptEnabled,
            code: taxExemptCode,
            states: taxExemptStates || []
          }
        },
        disableEditPersonalClosing
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      if (name === 'email' && this.emailInUse) {
        return false;
      }
      return $dirty ? !$error : null;
    }
  },
  watch: {
    clientConfig() {
      this.initializeForm();
    },
  },
  mounted() {
    this.initializeForm();
  }
}
</script>

<style lang="scss" scoped>
  .locations {
    height: 213px;
    overflow: auto;
  }
</style>
