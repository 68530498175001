<template>
  <b-row>
    <b-col>
      <div v-for="(profile, index) in form.senderProfiles" :key="profile.profile.profileID">
        <SenderProfileForm
          v-model="form.senderProfiles[index].profile"
          :readonly="!profile.editing || saving"
          :editing="profile.editing"
          :expanded="profile.expanded"
          :editable="!editing && !saving"
          :saving="saving"
          :isNew="creatingNewProfile"
          @expand="expandProfile(index)"
          @collapse="collapseProfile(index)"
          @edit="editProfile(index)"
          @delete="confirmDeleteProfile(index)"
          @save="saveProfile(index)"
          @cancel="cancelEditProfile(index)" />
      </div>

      <b-button
        v-if="!editing && !saving"
        variant="primary"
        size="lg"
        block
        @click.prevent="createNewProfile">Create New Profile</b-button>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions } from 'vuex';
import SenderProfileForm from '@/components/clients/senderProfileForm.vue';

export default {
  name: 'EditSenderProfilesForm',
  props: [
    'errors',
    'senderProfiles',
    'save'
  ],
  components: {
    SenderProfileForm
  },
  data() {
    return {
      form: {
        senderProfiles: []
      },
      creatingNewProfile: false,
      saving: false,
    }
  },
  computed: {
    editing() {
      return this.form.senderProfiles.filter(profile => profile.editing).length > 0;
    }
  },
  methods: {
    ...mapActions('ocapi', [
      'updateCustomerGroup'
    ]),
    createNewProfile() {
      this.creatingNewProfile = true;
      this.form = {
        senderProfiles: [
          ...this.form.senderProfiles,
          {
            profile: {
              profileID: ''
            },
            editing: true,
            expanded: true
          }
        ]
      };
    },
    setProfileEditing(index, value) {
      const newForm = {
        senderProfiles: [
          ...this.form.senderProfiles
        ]
      };
      newForm.senderProfiles[index].editing = value;
      this.form = newForm;
    },
    setProfileExpanded(index, value) {
      const newForm = {
        senderProfiles: [
          ...this.form.senderProfiles
        ]
      };
      newForm.senderProfiles[index].expanded = value;
      this.form = newForm;
    },
    confirmDeleteProfile(index) {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${this.form.senderProfiles[index].profile.profileName}?`, {
        title: 'Confirm delete profile',
        // size: 'sm',
        // buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        // footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.tableBusy = true;
            return this.deleteProfile(index);
          }
        })
        .then(() => {
          this.tableBusy = false;
          console.log('deleted');
        })
        .catch(err => {
          console.log(err);
          // An error occurred
        })
    },
    deleteProfile(index) {
      this.saving = true;
      const newForm = {
        senderProfiles: [
          ...this.form.senderProfiles.slice(0, index),
          ...this.form.senderProfiles.slice(index + 1)
        ]
      };
      this.form = newForm;
      this.save(this.getProfilesForSave())
        .then(() => {
          this.saving = false;
        })
        .catch(error => console.log(error));
    },
    editProfile(index) {
      this.expandProfile(index);
      this.setProfileEditing(index, true);
    },
    saveProfile(index) {
      this.saving = true;
      this.save(this.getProfilesForSave())
        .then(() => {
          this.saving = false;
          this.setProfileEditing(index, false);
          this.creatingNewProfile = false;
        })
        .catch(error => console.log(error));
    },
    cancelEditProfile(index) {
      if (this.creatingNewProfile) {
        this.deleteProfile(index);
        this.creatingNewProfile = false;
      } else {
        this.setProfileEditing(index, false);
      }
    },
    expandProfile(index) {
      this.setProfileExpanded(index, true);
    },
    collapseProfile(index) {
      this.setProfileExpanded(index, false);
    },
    initializeForm() {
      this.form = {
        senderProfiles: this.senderProfiles?.map(profile => ({
          profile,
          editing: false,
          expanded: false
        })) || []
      };
    },
    getProfilesForSave() {
      return this.form.senderProfiles.map(profileObject => ({ ...profileObject.profile }));
    }
  },
  watch: {
    senderProfiles() {
      this.initializeForm();
    },
  },
  mounted() {
    this.initializeForm();
  }
}
</script>
