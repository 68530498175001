<template>
  <div>
    <div v-if="products.length" class="p-3 bg-light">
      <b-form-row>
        <b-col>
          <b-form-input
            v-model="searchQuery"
            placeholder="Search SKU"
          ></b-form-input>
        </b-col>
        <b-col>
          <b-form-select
            v-model="modelLine"
            :options="modelLineOptions"
            :disabled="modelLineOptions.length === 1"
          ></b-form-select>
        </b-col>
      </b-form-row>
    </div>
    <b-table
      :class="tableClass"
      show-empty
      hover
      :fields="fields"
      :items="filteredProducts"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      responsive
      :busy="busy"
      ref="productTable"
      selectable
      @row-selected="onRowSelected"
      >
      <template #head(selected)>
        <b-icon
          v-if="products.length"
          :icon="allRowsSelected ? 'check-square-fill' : 'square'"
          @click.prevent="toggleSelectAllRows" />
        <span v-if="products.length" class="sr-only">{{ allRowsSelected ? 'Deselect all' : 'Select all' }}</span>
      </template>
      <template #cell(selected)="{ rowSelected }">
        <b-icon :icon="rowSelected ? 'check-square-fill' : 'square'"></b-icon>
        <span class="sr-only">{{ rowSelected ? 'Selected' : 'Not Selected' }}</span>
      </template>

      <template #cell(thumbnail)="data">
        <b-avatar
          rounded
          :src="data.item.image.link"
          variant="white"></b-avatar>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'SelectProducts',
  props: [
    'value',
    'products',
    'tableClass'
  ],
  data() {
    return {
      searchQuery: '',
      modelLine: '',
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      selected: [],
      busy: false,
      fields: [
        { key: 'selected', sortable: false, tdClass: 'align-middle' },
        { key: 'thumbnail', sortable: false, tdClass: 'align-middle' },
        { key: 'product_id', label: 'ID', sortable: true, tdClass: 'align-middle' },
        { key: 'product_name', label: 'Name', sortable: true, tdClass: 'align-middle' },
      ],
    }
  },
  computed: {
    allRowsSelected() {
      return this.selected.length && this.products.length === this.selected.length;
    },
    filteredProducts() {
      if (!this.searchQuery.length && this.modelLine === '') {
        return this.products;
      }
      return this.products.filter(product => {
        if (!this.productMatchesModelLine(product)) {
          return false;
        }
        return product.product_id.includes(this.searchQuery);
      })
    },
    modelLineOptions() {
      const modelLines = this.products.reduce((acc, product) => {
        return product?.c_hbc_ModelLine?.length ? [...acc, ...product.c_hbc_ModelLine] : [...acc];
      }, []);
      const modelLineValues = modelLines.map(modelLine => modelLine.value);
      return [
        {
          value: '',
          label: 'Filter by model line'
        },
        // remove duplicates
        ...modelLines.filter(({ value }, index) => !modelLineValues.includes(value, index + 1))
      ].map(line => ({ value: line.value, text: line.label }));
    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
      this.$emit('input', this.selected);
    },
    selectAllRows() {
      this.$refs.productTable.selectAllRows()
    },
    clearSelected() {
      this.searchQuery = '';
      this.modelLine = '';
      this.$refs.productTable.clearSelected()
    },
    toggleSelectAllRows() {
      if (this.allRowsSelected) {
        this.clearSelected();
      } else {
        this.selectAllRows();
      }
    },
    productMatchesModelLine(product) {
      if (this.modelLine === '') {
        return true;
      }
      if (!product?.c_hbc_ModelLine?.length) {
        return false;
      }
      return product.c_hbc_ModelLine.map(line => line.value).includes(this.modelLine);
    }
  },
  mounted() {
    this.selected = this.value;
  }
}
</script>
